<template>
  <div>
    <div class="mt-5">
      <v-container v-if="isLoading" type="image" class="loding">
        <v-skeleton-loader type="image"></v-skeleton-loader>
        <v-skeleton-loader type="image"></v-skeleton-loader>
        <!-- <v-skeleton-loader type="sentences"></v-skeleton-loader>
        <v-skeleton-loader type="sentences"></v-skeleton-loader>
        <v-skeleton-loader type="sentences"></v-skeleton-loader>
        <v-skeleton-loader type="sentences"></v-skeleton-loader> -->
      </v-container>
      <div v-else class="content">
        <v-stepper v-model="step" alt-labels>
          <v-stepper-header>
            <v-stepper-step :complete="step > 1" step="1">
              Généralité</v-stepper-step
            >
            <v-divider></v-divider>
            <v-stepper-step :complete="step > 2" step="2"
              >Client</v-stepper-step
            >
            <v-divider></v-divider>
            <v-stepper-step :complete="step > 3" step="3"
              >Installateur</v-stepper-step
            >
            <v-divider></v-divider>
            <v-stepper-step :complete="step > 4" step="4"
              >Adresse</v-stepper-step
            >
            <v-divider></v-divider>
            <v-stepper-step :complete="step > 5" step="5"
              >Information</v-stepper-step
            >
            <v-divider></v-divider>
            <v-stepper-step :complete="step > 6" step="6"
              >Chantier</v-stepper-step
            >
            <v-divider></v-divider>
            <v-stepper-step :complete="step > 7" step="7"
              >Règlement</v-stepper-step
            >
            <v-divider></v-divider>
            <v-stepper-step :complete="step > 8" step="8">Devis</v-stepper-step>
          </v-stepper-header>
          <div class="stepper-content">
            <component
              v-if="devisObjectLoaded"
              :is="currentStepComponent"
              @deletedIds="handleDeletedIds"
              ref="stepComponent"
              :devisObject="devisObject"
              @updateDevisObject="handleUpdateDevisObject"
            ></component>
          </div>
        </v-stepper>
        <div class="d-flex justify-content-end mt-4">
          <b-button
            v-if="step > 1"
            @click="prevStep"
            class="button-pre-style m-3"
          >
            Précédent
          </b-button>
          <b-button
            type="submit"
            @click="nextStep"
            class="button-suivant-style m-3"
          >
            {{ step === 8 ? "Terminer" : "Suivant" }}
          </b-button>
        </div>
        <div class="justify-content-end">
          <v-snackbar
            :timeout="2000"
            v-model="Valid"
            color="blue-grey"
            rounded="pill"
          >
            &#x2705; Modification appliquée avec succès.
          </v-snackbar>
        </div>
        <div class="justify-content-end">
          <v-snackbar
            :timeout="2000"
            v-model="Err"
            color="blue-grey"
            rounded="pill"
          >
            &#x274C; Vérifiez la date de fin, elle doit être supérieure ou égale
            à la date de début.
          </v-snackbar>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  props: {
    steps: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      Err: false,
      Valid: true,

      isLoading: true,
      step: 1,
      produitIds: [],
      devisObject: {
        data: {
          client: null,
          devis: {
            brouillon: 1,
            product_to_delete: [],
            groups_to_add: [
              {
                type_traveaux_id: 1,
                type_piece_id: 116,
                piece: "ss",
                sous_traitant_id:
                  this.devisObject?.data?.groupeLigneDocument[0]?.sous_traitant
                    ?.id || null,
                traveaux: "travaux",
                produits: [],
              },
            ],
          },
        },
      },
      product_to_delete: [],
      group_to_add: [],
      devisObjectLoaded: false,
    };
  },
  computed: {
    ...mapGetters(["getDevisObject", "getdevis"]),
    currentStepComponent() {
      return this.steps[this.step - 1].component;
    },
  },

  methods: {
    ...mapActions([
      "store_forfaitjson",
      "store_mainoeuvreJSON",
      "store_product",
      "store_productJSON",
      "updatedevis",
      "getonedevis",
      "saveDevisObject",
      "saveDevis",
      "update_devis_group_BAR",
      "transform_brouillon",
    ]),
    handleBeforeUnload(event) {
      event.returnValue = "Êtes-vous sûr de vouloir quitter ?";
    },

    async prevStep() {
      if (this.step > 1) {
        this.step--;

        this.devisObject = await this.getdevis;
      }
    },
    async produit(type, produit) {
      let response;

      switch (type) {
        case "Forfait":
          response = await this.store_forfaitjson(produit);

          break;
        case "Main d'oeuvre":
          produit.type_cout = 0;
          response = await this.store_mainoeuvreJSON(produit);

          break;
        case "Product":
          (produit.nom = produit.lib),
            (produit.information = produit.desc),
            (response = await this.store_productJSON(produit));

          break;
        default:
          break;
      }
      return response;
    },

    async nextStep() {
    
      this.Err = false;
      this.Valid = false;

      if (this.step < 7) {
        this.devisObject.data.brouillon = 1;
        this.devisObject.data.devis.brouillon = 1;
      }

      // Vérifier si on est à l'étape 2
      if (this.step === 2) {
        const stepComponent = this.$refs.stepComponent;

        if (stepComponent && typeof stepComponent.edit === "function") {
          try {
            await stepComponent.edit();
          } catch (error) {
            console.error(
              "Erreur lors de l'appel de edit à l'étape 2 :",
              error
            );
          }
        }
      }

      // Vérifier si on est à l'étape 3
      if (this.step === 3) {
        const stepComponent = this.$refs.stepComponent;

        if (stepComponent && typeof stepComponent.edit === "function") {
          try {
            await stepComponent.edit();
          } catch (error) {
            console.error(
              "Erreur lors de l'appel de edit à l'étape 3 :",
              error
            );
          }
        }
      }

      // Vérifier si on est à l'étape 5
      if (this.step === 5) {
        const stepComponent = this.$refs.stepComponent;

        if (stepComponent && typeof stepComponent.edit === "function") {
          try {
            await stepComponent.edit();
          } catch (error) {
            console.error(
              "Erreur lors de l'appel de edit à l'étape 5 :",
              error
            );
          }
        }
      }

      // Vérifier si on est à l'étape 7
      if (this.step === 7) {
        this.devisObject.data.brouillon = 0;
        this.devisObject.data.devis.brouillon = 0;
      }

      if (this.step === 8) {
        this.devisObject.data.brouillon = 0;
        this.devisObject.data.devis.brouillon = 0;
      }

      if (this.step === 6) {
        this.group_to_add = [];
        this.cleanGroup();
        let product_to_add = [];

        this.group_to_add.forEach(async (group) => {
          await group.produits.forEach(async (produit) => {
            if (!produit.searchInputValue || !produit.searchInputValue.id) {
              const response = await this.produit(produit.type, produit);

              produit.searchInputValue = response;
              produit.produit_id = response?.id;
              produit.type_ligne = produit.type;
              produit.totalHT = produit.unit_price * produit.quantite;

              product_to_add.push(produit);
              const uniqueProductsMap = new Map();

              product_to_add.forEach((produit) => {
                if (
                  !uniqueProductsMap.has(produit.produit_id) &&
                  !this.produitIds.includes(produit.produit_id)
                ) {
                  uniqueProductsMap.set(produit.produit_id, produit);
                }
              });

              product_to_add = Array.from(uniqueProductsMap.values());
              product_to_add = product_to_add.filter((produit) => {
                const produitId = Number(produit.produit_id);
                return !this.produitIds.includes(produitId);
              });

              product_to_add.forEach((produit) => {
                const produitId = Number(produit.produit_id);
                if (!this.produitIds.includes(produitId)) {
                  this.produitIds.push(produitId);
                }
              });
              const result = [
                {
                  id: this.group_to_add[0].id,
                  type_piece_id: 116,
                  piece: "piece1",
                  type_traveaux_id: 1,
                  sous_traitant_id:
                    this.devisObject?.data?.groupeLigneDocument[0]
                      ?.sous_traitant?.id || null,
                  traveaux: "trav1",
                  product_to_delete: this.deletedIds,
                  product_to_add: product_to_add,
                },
              ];
              await this.update_devis_group_BAR({ result });
            } else if (
              produit.searchInputValue &&
              produit.searchInputValue.id
            ) {
              produit.produit_id = produit.searchInputValue.id;
              produit.type_ligne = produit.type;
              produit.totalHT = produit.unit_price * produit.quantite;
              delete produit.searchInputValue;
              product_to_add.push(produit);
              const uniqueProductsMap = new Map();

              product_to_add.forEach((produit) => {
                if (
                  !uniqueProductsMap.has(produit.produit_id) &&
                  !this.produitIds.includes(produit.produit_id)
                ) {
                  uniqueProductsMap.set(produit.produit_id, produit);
                }
              });

              product_to_add = Array.from(uniqueProductsMap.values());
              product_to_add = product_to_add.filter((produit) => {
                const produitId = Number(produit.produit_id);
                return !this.produitIds.includes(produitId);
              });

              product_to_add.forEach((produit) => {
                const produitId = Number(produit.produit_id);
                if (!this.produitIds.includes(produitId)) {
                  this.produitIds.push(produitId);
                }
              });
            }
          });
        });
        const result = [
          {
            id: this.group_to_add[0].id,
            type_piece_id: 116,
            piece: "piece1",
            type_traveaux_id: 1,
            sous_traitant_id:
              this.devisObject?.data?.groupeLigneDocument[0]?.sous_traitant
                ?.id || null,
            traveaux: "trav1",
            product_to_delete: this.deletedIds,
            product_to_add: product_to_add,
          },
        ];
        await this.update_devis_group_BAR({ result });
      }

      await this.updateDevis()
        .then((result) => {
          if (!result) throw new Error("no result");
          if (this.step === this.steps.length) {
            this.$emit("complete");
            return;
          }
          this.step++;
          this.Valid = true;
          this.Err = false;
        })
        .catch(() => {
          this.Err = true;
          this.Valid = false;
        });
    },

    handleUpdateDevisObject(updatedDevisObject) {
      this.devisObject = updatedDevisObject;
      this.$store.dispatch("saveDevis", this.devisObject);
      this.devisObject = this.getdevis;
    },

    async fetchDevisObject(id) {
      try {
        this.devisObjectLoaded = true;
        const fetchedDevisObject = await this.getonedevis(id);
        this.$store.dispatch("saveDevis", fetchedDevisObject);
        this.devisObject = this.getdevis;
        const produitIds =
          this.devisObject.data.GroupeLigneDocument_produit.filter(
            (item) => item.produit_id
          ).map((item) => item.produit_id);
        this.produitIds = produitIds;

        if (this.devisObject) {
          this.isLoading = false;
        }
      } catch (error) {
        console.error("Failed to fetch devis object:", error);
      }
    },

    loadSavedDevisObject() {
      this.devisObject = this.getdevis;
    },
    async handleRouteChange() {
      const id = this.$route.params.id;
      if (id) {
        try {
          await this.fetchDevisObject(id);
        } catch (error) {
          console.error("Failed to update devisObject on route change:", error);
        }
      } else {
        this.loadSavedDevisObject();
      }
    },
    handleDeletedIds(deletedIds) {
      this.deletedIds = deletedIds;
    },
    cleanGroup() {
      this.devisObject.data.groupeLigneDocument.forEach((group) => {
        group.produits = [];
        this.devisObject.data?.GroupeLigneDocument_produit.forEach((p) => {
          if (p.groupelignedocument_id == group.id) {
            group.produits.push(p);
          }
        });
        this.group_to_add.push(group);
      });
    },

    async updateDevis() {
      try {
        const devis = {
          ...this.devisObject.data.devis,
          product_to_delete: this.product_to_delete,
          group_to_add: this.group_to_add,
        };

        if (this.step === 8) {
          devis.brouillon = 0;
        }
        const reponse = await this.updatedevis(devis);
        this.$store.dispatch("saveDevis", reponse.data);
        this.devisObject = this.getdevis;

        return reponse;
      } catch (error) {
        console.error("Failed to update devisObject:", error);
      }
    },
  },
  created() {
    this.isLoading = true;

    this.handleRouteChange();

    window.addEventListener("beforeunload", this.handleBeforeUnload);
  },
  beforeRouteLeave(to, from, next) {
    if (this.step !== 7) {
      this.updateDevis()
        .then(() => {
          next();
        })
        .catch((error) => {
          console.error(
            "Failed to update devisObject before route change:",
            error
          );
          next(false);
        });
    }
  },
  watch: {
    $route() {
      this.handleRouteChange();
    },
  },
  beforeDestroy() {
    if (this.step !== 7) {
      this.updateDevis()
        .then(() => {})
        .catch((error) => {
          console.error(
            "Failed to update devisObject before component destruction:",
            error
          );
        });
    }
    window.removeEventListener("beforeunload", this.handleBeforeUnload);
  },
};
</script>

<style lang="scss">
.v-stepper__step--complete .v-stepper__step__step {
  background-color: #24316d !important;
  border-color: #24316d !important;
  color: white !important;
}

.v-stepper__step--active .v-stepper__step__step {
  background-color: #24316d !important;
  border: 1px solid #24316d !important;
  color: white !important;
  box-shadow: 0 0 0 1px #fff inset;
  border-radius: 50%;
}

.v-stepper__step--complete + .v-divider {
  border-color: #24316d !important;
}

.v-divider {
  border-color: rgba(0, 0, 0, 0.12);
}

::before {
  text-decoration: inherit;
  vertical-align: inherit;
  color: white !important;
}
.v-stepper__header {
  height: 72px;
  align-items: stretch;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  box-shadow: none !important;
}
.theme--light.v-stepper .v-stepper__step--active .v-stepper__label {
  text-shadow: 0px 0px 0px black;
  color: #24316d !important;
}

.theme--light.v-stepper .v-stepper__step--complete .v-stepper__label {
  text-shadow: 0px 0px 0px black;
  color: #24316d !important;
}

.v-sheet.v-stepper:not(.v-sheet--outlined) {
  box-shadow: none !important;
}

.button-pre-style,
.button-suivant-style {
  background-color: #24316d;
  color: white;
  font-size: 13px;
  box-shadow: none !important;
}

.button-suivant-style:hover,
.button-suivant-style:focus,
.button-suivant-style:active {
  background-color: #24316d;
  color: white;
}

/* Responsive Styles */
@media only screen and (max-width: 1200px) {
  .v-stepper__step--complete .v-stepper__step__step,
  .v-stepper__step--active .v-stepper__step__step {
    font-size: 12px;
  }

  .stepper-buttons {
    flex-direction: column;
    align-items: center;
  }

  .button-pre-style,
  .button-suivant-style {
    font-size: 16px;
    padding: 8px 16px;
  }
}

@media only screen and (max-width: 530px) {
  .v-stepper__step--complete + .v-divider {
    border-color: white !important;
  }
  .v-stepper--alt-labels .v-stepper__step {
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    flex-basis: 75px;
  }
  .theme--light.v-stepper .v-stepper__header .v-divider {
    border-color: #fff;
  }

  .v-divider {
    display: block;
    height: 0px;
    max-height: 0px;
    border: solid;
    border-width: thin 0 0 0;
    transition: inherit;
  }
  .v-stepper--alt-labels .v-stepper__step {
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }

  .v-stepper__step {
    align-items: center;
    display: flex;
    flex-direction: row;
  }
}
@media only screen and (max-width: 900px) {
  .v-stepper__step--complete .v-stepper__step__step,
  .v-stepper__step--active .v-stepper__step__step {
    font-size: 10px;
  }

  .stepper-buttons {
    flex-direction: column;
    align-items: center;
  }

  .button-pre-style,
  .button-suivant-style {
    font-size: 10px;
    padding: 6px 12px;
  }
}

@media only screen and (max-width: 600px) {
  .v-stepper__step--complete .v-stepper__step__step,
  .v-stepper__step--active .v-stepper__step__step {
    font-size: 8px;
  }

  .stepper-buttons {
    flex-direction: column;
    align-items: center;
  }

  .button-pre-style,
  .button-suivant-style {
    font-size: 8px;
    padding: 4px 8px;
  }
}

.stepper-content {
  padding: 0 10px;
}
</style>
