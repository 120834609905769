<template>
  <div >
    <div class="row mt-4">
      <div class="col-md-6 col-lg-6 col-sm-12">
        <form @submit.prevent="addCLI">
          <div class="background-white mt-3">
            <div class="title">
              <div class="position">Client</div>
            </div>
          </div>
          <div class="center borderPointier mt-3 p-3">
            <b-form-group :label="$t('CUSTOMER')" class="style">
              <multiselect
                v-model="selectedClient"
                :searchable="true"
                :close-on-select="true"
                :multiple="false"
                :options="getAllclients"
                label="nom"
                track-by="id"
                :placeholder="$t('Select_agent')"
                class="form-input custom-placeholder custom-input"
              />
            </b-form-group>
            <b-form-group label="Type">
              <b-form-select
                v-model="clientData.type"
                :options="optionsTypeClient"
                :placeholder="$t('Type')"
                aria-describedby="type-feedback"
                :class="{ 'form-control': false }"
                class="form-input custom-placeholder custom-input"
              >
              </b-form-select>
            </b-form-group>
            <div class="fieldset mt-3">
              <div class="legend mb-1">{{ $t("SURNAME") }}</div>
              <b-form-input
                v-model="clientData.prenom"
                :placeholder="$t('PRENOM')"
                oninput="this.value = this.value.toUpperCase()"
                aria-describedby="prenom-feedback"
                class="form-input custom-placeholder custom-input"
                >}
              </b-form-input>
            </div>

            <div class="fieldset">
              <div class="legend mb-1">{{ $t("NAME") }}</div>
              <b-form-input
                v-model="clientData.nom"
                :placeholder="$t('NAME')"
                aria-describedby="nom-feedback"
                oninput="this.value = this.value.toUpperCase()"
                class="form-input custom-placeholder custom-input"
                :class="{ 'form-control': false }"
              >
              </b-form-input>
            </div>

            <div class="fieldset mt-3">
              <div class="legend mb-1">{{ $t("STREET") }}</div>
              <b-form-input
                v-model="clientData.rue"
                :placeholder="$t('STREET')"
                aria-describedby="rue-feedback"
                class="form-input custom-placeholder custom-input"
              ></b-form-input>
            </div>

            <div class="row mt-3 adresse">
              <div class="col-lg-6 col-md-12 col-sm-12">
                <div class="legend mb-1">{{ $t("POSTAL_CODE") }}</div>
                <b-form-input
                  v-model="clientData.cp"
                  :placeholder="$t('POSTAL_CODE')"
                  aria-describedby="cp-feedback"
                  class="form-input custom-placeholder custom-input"
                ></b-form-input>
              </div>

              <div class="col-lg-6 col-md-12 col-sm-12">
                <div class="legend mb-1">{{ $t("CITY") }}</div>
                <b-form-input
                  v-model="clientData.ville"
                  :placeholder="$t('CITY')"
                  aria-describedby="ville-feedback"
                  class="form-input custom-placeholder custom-input"
                ></b-form-input>
              </div>
            </div>

            <div class="row mt-3 adresse">
              <div class="col-lg-6 col-md-12 col-sm-12">
                <b-form-group :label="$t('ZONE')">
                  <b-form-select
                    v-model="clientData.zone"
                    :options="optionsZone"
                    text-field="text"
                    value-field="value"
                    class="form-input custom-placeholder custom-input"
                  >
                    <template #first>
                      <b-form-select-option :value="null" :disabled="selected"
                        >-- {{ $t("ZONE") }} --</b-form-select-option
                      >
                    </template>
                  </b-form-select>
                </b-form-group>
              </div>

              <div class="col-lg-6 col-md-12 col-sm-12">
                <b-form-group :label="$t('COUNTRY')">
                  <multiselect
                    v-model="clientData.pays"
                    :placeholder="$t('COUNTRY')"
                    :searchable="true"
                    :close-on-select="true"
                    :multiple="false"
                    :options="getAllcountries"
                    label="name"
                    track-by="id"
                    class="form-input custom-placeholder custom-input"
                  >
                    <template slot="noResult">
                      {{ $t("NO_DATA_FOUND") }}
                    </template>
                  </multiselect>
                </b-form-group>
              </div>
            </div>
          </div>
        </form>
      </div>

      <div class="col-md-6 col-lg-6 col-sm-12">
        <div class="background-white mt-3">
          <div class="title">
            <div class="position">Client</div>
          </div>
        </div>
        <form @submit.prevent="addCLI">
          <div class="borderPointier mt-3 pt-3 px-3">
            <b-form-group :label="$t('précaire')" class="mt-2">
              <b-form-select
                class="form-input custom-placeholder custom-input"
                v-model="clientData.precarite"
                placeholder="précaire"
                :options="optionsprécaire"
                text-field="text"
                value-field="value"
              >
                <template #first>
                  <b-form-select-option :value="null" disabled
                    >-- {{ $t("précaire") }} --</b-form-select-option
                  >
                </template>
              </b-form-select>
            </b-form-group>
            <div class="fieldset">
              <div class="legend mb-1">{{ $t("EMAIL") }}</div>
              <b-form-input
                type="email"
                v-model="clientData.email"
                :placeholder="$t('CLIENT_EMAIL')"
                aria-describedby="email-feedback"
                class="form-input custom-placeholder custom-input"
              ></b-form-input>
            </div>

            <div class="fieldset mb-4">
              <b-form-group
                v-modal="this.clientData.tel"
                label-for="telephone"
                class="mb-1 mt-3"
              >
                <template>
                  <phone
                    :tel="phone"
                    @phoneNumber="formatNumber($event)"
                    class="form-input custom-placeholder custom-input"
                  ></phone>
                </template>
              </b-form-group>
            </div>
          </div>
        </form>
        <form @submit.prevent="submitForm">
          <div class="borderPointier mt-3 pt-3 px-3">
            <div class="background-white">
              <div class="title">
                <div class="position">Logement</div>
              </div>
            </div>
            <b-form-group :label="$t('TYPE_CHAUFFAGE')" class="mt-2">
              <b-form-select
                class="form-input custom-placeholder custom-input"
                v-model="logementData.type_chauffage"
                :placeholder="$t('TYPE_CHAUFFAGE')"
                :options="optionstypechauffage"
                text-field="text"
                value-field="value"
              >
                <template #first>
                  <b-form-select-option :value="null" disabled
                    >-- {{ $t("TYPE_CHAUFFAGE") }} --</b-form-select-option
                  >
                </template>
              </b-form-select>
            </b-form-group>
            <b-form-group :label="$t('TYPE_LOGEMENT')">
              <b-form-select
                :placeholder="$t('TYPE_LOGEMENT')"
                class="form-input custom-placeholder custom-input"
                v-model="logementData.type"
                :options="optionstypelogement"
                text-field="text"
                value-field="value"
              >
                <template #first>
                  <b-form-select-option :value="null" disabled
                    >-- {{ $t("TYPE_LOGEMENT") }} --</b-form-select-option
                  >
                </template>
              </b-form-select>
            </b-form-group>

            <b-row>
              <b-col md="6">
                <b-form-group :label="$t('Ancienté')">
                  <b-form-input
                    type="number"
                    :placeholder="$t('Ancienté')"
                    v-model="logementData.ancienter"
                    required
                    class="form-input custom-placeholder custom-input"
                  ></b-form-input>
                </b-form-group>
              </b-col>

              <b-col md="6">
                <b-form-group :label="$t('Surface')">
                  <b-form-input
                    type="number"
                    :placeholder="$t('Surface')"
                    v-model="logementData.surface"
                    required
                    class="form-input custom-placeholder custom-input"
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>

            <b-form-group :label="$t('Parcelle Cadastrale')" class="mb-4">
              <b-form-input
                type="text"
                :placeholder="$t('Parcelle cadastrale')"
                v-model="logementData.parcelle_cadastrale"
                required
                class="form-input custom-placeholder custom-input"
              ></b-form-input>
            </b-form-group>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import "vue-phone-number-input/dist/vue-phone-number-input.css";
import phone from "@/components/ui/phone.vue";
import { mapGetters, mapActions } from "vuex";
export default {
  created() {
    this.all_countries({ page: this.page, perPage: 100000 });
    this.all_clients({
      search: "",
    });
  },

  components: {
    phone,
  },
  data() {
    return {
      phone: {
        phone_number: this.devisObject.data.client?.phone?.phone_number || null,
        iso_code: this.devisObject.data.client?.phone?.iso_code || null,
      },
      pay: {
        pays: this.devisObject.data.client?.pays || null,
      },
      inputSearchValue: null,
      selected: false,

      defaultPrenom: "Valeur par défaut",
      clientData: this.initializeClientData(),
      logementData: this.initializeLogementData(),
      optionsTypeClient: [
        { value: "type.particulier", text: this.$t("PARTICULAR") },
        { value: "type.professionnel", text: this.$t("PROFESSIONAL") },
      ],
      optionsZone: [
        { value: "h1", text: "H1" },
        { value: "h2", text: "H2" },
        { value: "h3", text: "H3" },
      ],
      optionstypechauffage: [
        { value: "combustible_bois", text: this.$t("Combustible/Bois") },
        { value: "electrique", text: this.$t("Electrique") },
        { value: "gaz", text: this.$t("Gaz") },
        { value: "fuel", text: this.$t("Fuel") },
        { value: "bois", text: this.$t("Bois") },
        { value: "pac", text: "PAC" },
      ],
      optionsprécaire: [
        { value: "Classique", text: this.$t("Classique") },
        { value: "Précaire", text: this.$t("Précaire") },
      ],
      optionstypelogement: [
        { value: "maison_individuelle", text: this.$t("Maison individuelle") },
        { value: "appartement", text: this.$t("Appartement") },
      ],
      search: "",

      form: {},
      clientAdd: this.devisObject?.data?.client === null,
      logementAdd: this.devisObject?.data?.devis?.logement === null,
    };
  },
  props: {
    devisObject: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters(["getAllcountries", "getAllclients"]),
    selectedClient: {
      get() {
        if (typeof this.clientData === "object" && this.clientData !== null) {
          return this.clientData;
        } else {
          return this.getAllclients.find(
            (option) => option.id === this.clientData
          );
        }
      },
      set(value) {
        this.clientData = value;
        this.selected = true;
      },
    },
  },

  watch: {
    inputSearchValue(newValue) {
      if (newValue.trim() === "") {
        this.selected = false;
      }
    },
  },
  methods: {
    ...mapActions([
      "editLogement",
      "edit_client",
      "storeLogement",
      "all_clients",

      "pays",
      "client",
      "store_client",
      "all_countries",
    ]),

    formatNumber(event) {
      if (event && event.number && event.country) {
        this.clientData.tel = {
          phone_number: event.number,
          phone_iso_code: event.country.iso2,
        };
        this.resultValid = event.isValid;
      }
    },
    initializeLogementData() {
      const logement = this.devisObject?.data?.devis?.logement || {};
      return {
        type_chauffage: logement.type_chauffage || null,
        type: logement.type || null,
        ancienter: logement.ancienter || "",
        surface: logement.surface || "",
        parcelle_cadastrale: logement.parcelle_cadastrale || "",
      };
    },
    initializeClientData() {
      const client = this.devisObject?.data?.client || {};
      return {
        nom: client.nom || "",
        prenom: client.prenom || "",
        rue: client.rue || "",
        cp: client.cp || "",
        ville: client.ville || "",
        id: client.id || null,
        precarite: client.precarite || null,
        type: client.type || null,
        email: client.email || null,
        pays: client.pays || null,
        zone: client.zone || null,
        tel: {
          phone_number: client.tel?.phone_number || null,
          phone_iso_code: client.tel?.phone_iso_code || null,
        },
      };
    },

    async addCLI() {
      if (this.selected) {
        const client = { ...this.clientData };
        await this.edit_client({ data: client });
        const loges = { ...this.logementData, client_id: this.clientData.id };

        const res = await this.storeLogement(loges);

        this.emitUpdate();
        this.devisObject.data.devis.logement_id = res.data.id;
        this.devisObject.data.devis.client_id = this.clientData.id;
        return { logement: res, client: this.clientData };
      } else {
        if (this.clientAdd) {
          const data = {
            ...this.clientData,
            pays: this.clientData.pays.iso_code,
            phone_iso_code: this.clientData.tel.phone_number,
          };
          if (this.clientData.tel && this.clientData.tel.phone_number) {
            var num = this.clientData.tel.phone_number.indexOf(" ");
            data.phone_number = this.clientData.tel.phone_number
              .slice(num + 1)
              .split(" ")
              .join("");
            data.tel = data.phone_number;
            data.phone_iso_code = this.clientData.tel.phone_iso_code;
          }
          data.entreprise_id = this.form.id;
          data.zone = this.clientData.zone;
          data.precarite = this.clientData.precarite;
          const response = await this.store_client(data);

          const loges = { ...this.logementData, client_id: response.id };
          const res = await this.storeLogement(loges);

          this.devisObject.data.devis.logement_id = res.data.id;
          this.devisObject.data.devis.client_id = response.id;

          this.emitUpdate();
          return { logement: res, client: response };
        }
      }
    },

    async edit() {
      if (this.clientAdd) {
        await this.addCLI();
        this.emitUpdate();
      } else {
        if (this.devisObject.data.devis.client.id !== this.clientData.id) {
          this.devisObject.data.devis.client_id = this.clientData.id;
          const client = { ...this.clientData, id: this.clientData.id };
          await this.edit_client({ data: client });
          const loges = { ...this.logementData, client_id: this.clientData.id };
          await this.storeLogement(loges);

          this.emitUpdate();
        } else {
          const client = {
            ...this.clientData,
            id: this.devisObject.data.devis.client.id,
          };
          await this.edit_client({ data: client });

          const log = {
            ...this.logementData,
            id: this.devisObject.data.devis.logement.id,
          };
          await this.editLogement(log);

          this.emitUpdate();
        }
      }
    },

    emitUpdate() {
      this.$emit("updateDevisObject", this.devisObject);
    },
    selectclient(client) {
      this.clientData = { ...client };
      this.devisObject.data.client = { ...client };
      this.selected = true;
      this.emitUpdate();
    },
  },
};
</script>
<style scoped>
.custom-placeholder::placeholder {
  font-size: 13px;
}

.form-input {
  font-size: 13px;
  height: 41.1px !important;
}
.borderPointier {
  border: 2px dashed #24316d;
  position: relative;
}
.input-style {
  margin-bottom: 1rem;
  border-radius: 4px;
  border: 1px solid #eaebec;
  padding: 0.5rem 0.75rem;
  background-color: #fff;
}

.background-white {
  display: inline-block;
  padding: 0.5rem 1rem;
  margin-bottom: 1rem;
  position: absolute;
  top: -1rem;
  left: 0;
  right: 0;
  z-index: 1;
  text-align: center;
}
.title {
  color: #24316d;
  position: relative;
  z-index: 2;
  margin: 0;
}

.position {
  background-color: white;
  display: inline-block;
  padding: 0.05rem 2rem;
}
.form-control {
  box-shadow: none;
}
.custom-input {
  box-shadow: none;
}
.col-md-12 {
  padding: 0px 15px 0px 15px !important;
}
::v-deep .multiselect__tags {
  border: 1px solid #ced4da !important;
  font-size: 13px !important;
}
::v-deep .multiselect__input,
.multiselect__single {
  font-size: 13px !important;
}
::v-deep .multiselect__placeholder {
  color: #6c757d;
  display: inline-block;
  margin-bottom: 10px;
  padding-top: 2px;
}
::v-deep .multiselect__single {
  font-size: 13px !important;
}
.legend {
  font-size: 16px;
}
</style>
